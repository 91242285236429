<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <Search />
        <v-btn
          depressed
          color="success"
          class="mb-3"
          @click="$router.push({ name: 'dashboard-perfis-novo' })"
        >
          <v-icon>add</v-icon>Novo
        </v-btn>
        <v-data-table
          class="elevation-1"
          :headers="table.header"
          :items="table.content"
          :item-key="'id'"
          :loading="table.isLoading"
          :items-per-page="table.paginate.inputPage.size"
          :options.sync="pagination"
          :server-items-length="table.total"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, 100],showFirstLastPage: true, showCurrentPage: true }"
          @click:row="goToDetail"
        >
          <template v-slot:item.actions="{ item }">
            <router-link
              :to="{ name: 'dashboard-perfis-editar', params: { id: item.id } }"
              title="Visualizar"
            >
              <v-icon>visibility</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Search from './Search';

  export default {
    components: {
      Search
    },
    data: () => ({
      pagination: null
    }),
    computed: {
      ...mapState('perfil', ['table'])
    },
    watch: {
      pagination: {
        handler() {
          this.changePagination();
        },
        deep: true
      }
    },
    destroyed() {
      this.clear();
    },
    methods: {
      changePagination() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        const paginate = {
          ...this.table.paginate,
          inputPage: {
            page: page,
            size: itemsPerPage,
            order: sortBy.length > 0 ? sortBy[0] : 'id',
            descendingOrder: sortDesc.length > 0 ? sortDesc[0] : true
          }
        };
        this.changePage(paginate);
        this.search();
      },
      goToDetail({ id }) {
        this.$router.push({ name: 'dashboard-perfis-editar', params: { id } });
      },
      ...mapActions('perfil', { search: 'SEARCH' }),
      ...mapActions('perfil', { clear: 'CLEAR' }),
      ...mapActions('perfil', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>
